export default {
  create: {
    title: 'Create new named source',
    save: 'Save changes',
    cancel: 'Cancel',
    form: {
      expand: 'Expand more info',
      collapse: 'Collapse'
    },
    formConfiguration: {
      title: 'Configuration',
      info: 'Assign a unique name to this named source and a SQL statement.',
      name: 'Name',
      namePlaceholder: 'unique name for your named source',
      nameHelperText:
        'You’ll use this name to reference this Named source in your code. Name should be 3-50 characters long, containing only lowercase letters and numbers. Dashes and underscores are allowed if they’re not leading or tailing.',
      nameErrorMessage:
        'Name must be 3-50 characters long, containing only lowercase letters and numbers. Dashes and underscores are allowed if they’re not leading or tailing.',
      nameErrorAlreadyUsedMessage: 'There is already a named source with this name. Define a new unique name.',
      sql: 'SQL statement',
      sqlPlaceholder: 'Type your SQL code here',
      sqlHelperText:
        'This is the complete SQL statement that will be executed when referencing this Named source in your application code. All SQL functionalities are supported, including query parameters.'
    },
    success: 'Named source created successfully',
    error: 'CARTO could not create your Named source. Please try again, or contact us if the problem persists.'
  },
  edit: {
    title: 'Named source',
    save: 'Save changes',
    cancel: 'Cancel',
    success: 'Named source edited successfully',
    error: 'CARTO could not save your Named source. Please try again, or contact us if the problem persists.'
  },
  actions: {
    edit: 'View or edit named source',
    delete: 'Delete'
  },
  delete: {
    success: 'Named source has been deleted',
    error: 'CARTO could not delete your Named source. Please try again, or contact us if the problem persists.'
  },
  deleteDialog: {
    title: 'Delete named source',
    info: 'Are you sure you want to delete {namedSourceName}? Applications using references to this Named source will stop working. This action cannot be undone.',
    delete: 'Yes, Delete',
    cancel: 'Cancel'
  }
}
