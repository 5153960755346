import common from './en/common'
import account from './en/account'
import accessToken from './en/accessToken'
import apps from './en/apps'
import developers from './en/developers'
import connections from './en/connections'
import namedSources from './en/namedSources'
import dataCatalog from './en/dataCatalog'
import imports from './en/imports'
import maps from './en/maps'
import plugins from './en/plugins'
import spatialSql from './en/spatialSql'
import builder from './en/builder'
import home from './en/home'
import dataExplorer from './en/dataExplorer'
import jobs from './en/jobs'
import dataObservatory from './en/dataObservatory'
import quotas from './en/quotas'
import hubspot from './en/hubspot'
import keplerOverrides from './en/keplerOverrides'
import sidebar from './en/sidebar'
import analysis from './en/analysis'
import helpPanel from './en/helpPanel'
import workflows from './en/workflows'
import integrations from './en/integrations'
import news from './en/news'
import tags from './en/tags'

export default {
  common,
  account,
  accessToken,
  apps,
  developers,
  namedSources,
  connections,
  'data-observatory': dataObservatory,
  'data-catalog': dataCatalog,
  imports,
  maps,
  plugins,
  spatialSql,
  builder,
  home,
  'data-explorer': dataExplorer,
  jobs,
  quotas,
  hubspot,
  compare: {
    types: {
      absolute: 'Absolute'
    }
  },
  ...keplerOverrides,
  sidebar,
  analysis,
  helpPanel,
  workflows,
  integrations,
  news,
  tags
}
