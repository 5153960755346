export default {
  sections: {
    title: 'Developers',
    overview: 'Overview',
    credentials: 'Credentials',
    namedSources: 'Named sources',
    workflows: 'Workflow API endpoints'
  },
  title: 'CARTO for developers',
  apiUrl: 'API Base URL',
  lds: 'LDS Token',
  disabledNoConnections: 'Please create a connection to your data warehouse first',
  accessToken: {
    title: 'API Access Tokens',
    content: {
      title: 'You have created <strong>{count, plural, one {# token} other {# tokens}}</strong>',
      link: 'Manage API Access Tokens'
    }
  },
  banner: {
    description: 'Start building powerful geospatial applications with CARTO + deck.gl',
    button: 'Create your first set of credentials'
  },
  applications: {
    title: 'Built applications',
    button: 'Create new',
    buttonShort: 'New',
    create: 'Create {type}',
    deleteSuccess: '{type} has been deleted',
    createSuccess: '{type} created successfully',
    type: {
      spa: 'SPA OAuth Client',
      m2m: 'M2M OAuth Client',
      token: 'API Access Token'
    },
    table: {
      name: 'Name',
      clientId: 'Client ID',
      secret: 'Client Secret',
      shared: 'Shared'
    },
    wouldExceedQuotaModal: {
      create: {
        title: "Couldn't create new application"
      },
      moreInfo: {
        title: "Can't create new applications"
      },
      message: 'Check the exceeded quotas below before creating a new application.',
      alertMessage:
        'You can’t create any more applications with your current plan. Free up space by deleting other applications or request to be contacted for an upgrade.',
      quotaTitle: 'Applications'
    },
    quotaHit: 'You have used all the applications ({limit}) available in your current plan.'
  },
  guide: {
    title: 'HOW TO START',
    button: 'Discover more guides',
    1: 'Building your first public geospatial application with CARTO + deck.gl',
    2: 'Building an application with user authentication via CARTO + SSO',
    3: 'Integrating CARTO + deck.gl into an existing application'
  },
  docs: {
    button: 'Check our documentation',
    1: {
      title: 'CARTO for developers',
      description: 'Find all documentation and reference for CARTO for Developers'
    },
    2: {
      title: 'Key Concepts',
      description: 'Learn about authentication, APIs, connections and visualization'
    },
    3: {
      title: 'CARTO + DECK.GL',
      description: 'Documentation for our visualization library'
    }
  },
  examples: {
    1: {
      title: 'CARTO + DECK.GL',
      description: 'A simple “Hello World” visualization with a point-based layer'
    },
    2: {
      title: 'CARTO + DECK.GL',
      description: 'Using SQL parameters to dynamically filter data'
    },
    3: {
      title: 'CARTO + DECK.GL',
      description: 'Visualizing 481M polygons using a static tileset'
    }
  },
  feed: {
    title: 'Development updates',
    viewAll: 'View all updates'
  },
  table: {
    description:
      'All requests to the CARTO APIs must be authenticated. When starting a project or building an application you can choose between three types of authentication strategies. Read more about each of them in <a>our documentation</a>',
    credential_tabs: {
      spa: 'SPA OAuth Clients',
      m2m: 'M2M OAuth Clients',
      token: 'API Access Tokens'
    },
    copy: 'Copy Client ID',
    copySecret: 'Copy Client Secret',
    copyId: 'Copy ID',
    copied: 'Copied to clipboard'
  },
  updates: {
    error: {
      title: "The updates couldn't be shown right now, you can still read the {developersDocsLink}",
      developersDocsLink: 'developers news here'
    }
  },
  deleteDialog: {
    title: 'Delete {type}'
  },
  empty: {
    title: "You don't have any {type} yet",
    subtitle: 'Create one to start developing applications with CARTO',
    button: 'Create your first {type}',
    learn: 'Learn more about {type}'
  },
  forms: {
    common: {
      credential: 'Credential'
    },
    token: {
      description:
        "API Access Tokens are the simplest method of authentication to use the CARTO APIs. API Access Tokens can be restricted to specific data grants and they are NOT tied to a specific user's set of permissions. Learn more about them in <a>our documentation</a>"
    },
    spa: {
      description:
        'Authenticate each user where they need to introduce their CARTO credentials — or their SSO credentials. If you want to know more about how to build SPA applications using CARTO and OAuth clients CARTO read <a>our documentation</a>',
      defaultSwitchHelper:
        'When this is enabled, CARTO will setup this SPA OAuth Client automatically using your Application URL. Alternatively, you can disable this setting and specify further rules for your application with specific URLs and Origins.'
    },
    m2m: {
      description:
        'Use M2M OAuth Clients to obtain OAuth Access Tokens on demand, typically from a backend application. Recommended to integrate CARTO into your current application. Learn more about M2M OAuth Clients in <a>our documentation</a>'
    }
  },
  namedSources: {
    createButton: 'Create new',
    search: 'Search by name',
    empty: {
      title: "You don't have any named sources yet",
      subtitle: 'Create one to start using them in your applications with CARTO',
      learn: 'Learn more about named sources'
    },
    noSearchResults: {
      title: 'No named sources were found'
    },
    description:
      'When developing with CARTO, you can decide whether or not SQL queries are exposed in your application. Named sources provide a mechanism to completely remove SQL queries from your application code and from all API requests, using references instead. Learn more about Named sources in <a>our documentation</a>',
    table: {
      titleName: 'Name',
      titleLastUpdated: 'Last modified',
      titleSQL: 'SQL statement',
      copy: 'Copy name',
      copied: 'Copied to clipboard'
    },
    noNamedSources: {
      button: 'Create your first named source',
      or: 'or'
    }
  },
  workflows: {
    search: 'Search API-enabled workflows...',
    empty: {
      title: "You don't have any API-enabled Workflows",
      subtitle: 'Workflows in CARTO can be executed via API, but they need to be API-enabled first.',
      learn: 'Learn more about Workflow API endpoints'
    },
    description:
      'This is a collection of all the workflows you can access that have been enabled to be called via API. Executing a workflow via an API call allows merging workflows with bigger processes, running analytics pipelines from external applications and further integrations that enable a wide range of use cases. Learn more about them in <a>our documentation</a>',
    table: {
      copy: 'Copy API URL',
      go: 'Open workflow'
    }
  },
  overview: {
    manage: 'Manage credentials',
    token: {
      description:
        'Generate simple static credentials that can be restricted to specific data grants, instead of specific users.'
    },
    spa: {
      description:
        'Users will login to your application using their usual CARTO credentials, including any Single Sign-On (SSO) integration.'
    },
    m2m: {
      description:
        'Obtain an <strong>OAuth Access Token</strong> on demand. Recommended to integrate CARTO into your current application.'
    }
  },
  credentialDialog: {
    learn: 'Learn more in our documentation'
  },
  description:
    'All endpoints in the CARTO APIs will use this base URL that is specific to the deployment method or region of your CARTO organization. Learn more about its usage in <a>our documentation</a>'
}
