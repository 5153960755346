export default {
  title: 'Analysis',
  running: 'Running analysis...',
  errorNoResult: "The table was created but it's empty, because the selected parameters produced no results.",
  sqlRun: 'Run SQL analysis',
  sqlPreview: 'Preview SQL analysis query',
  asTable: 'Save results in a new table',
  common: {
    aggOpPlaceholder: 'Select an operation'
  },
  modal: {
    destination: {
      createTable: 'Create table'
    },
    confirmation: {
      pleaseReview: 'Please, review the details:',
      explanation:
        'The result of the analysis will be persisted into a table in the selected destination and added as a new source to the current map.',
      run: 'Run analysis and add source'
    }
  },
  multipleDropdown: {
    clear: 'Clear all'
  },
  secondSource: {
    otherFromConnection: 'Select a table from {connName}'
  },
  intersect: {
    description:
      'A column with aggregated data from the second source will be added to the base source when their geometries intersect.',
    data: 'Data',
    params: 'Parameters',
    base: 'Base source',
    second: 'Second source',
    secondSourceEmpty: "There aren't any valid sources in the map",
    aggregationOp: 'Aggregation operation',
    secondColumn: 'Aggregation column',
    secondSourceHelp: 'Data sources involved in the analysis must belong to the same connection.'
  },
  addColumn: {
    title: 'Select join keys in both sources and the columns to be included in the result.',
    baseSource: 'From base source',
    newColumns: 'Columns included in the result',
    secondSource: 'From second source',
    secondSourceLabel: 'Second source',
    secondSourcePlaceholder: 'Select a data source',
    secondColumnPlaceholder: 'Select the key column',
    secondColumnPlaceholder2: 'Select a column',
    selectColumns: 'Select columns',
    helper:
      'Data sources involved in the analysis must belong to the same connection. Join key columns must be of the same type.'
  },
  creatingTable: 'Creating table...',
  buffer: {
    title: 'Select the buffer creation parameters.',
    data: 'Data',
    params: 'Parameters',
    tracts: 'Tracts',
    tractsHelp: 'Select the number of concentric tracts, spaced by the selected distance',
    distance: 'Distance',
    measure: {
      m: 'm',
      km: 'km',
      unit: 'Measure unit'
    },
    type: {
      tooltips: {
        combined: 'Combine boundaries producing a single polygon',
        nonCombined: 'Individual polygons with intact boundaries'
      }
    }
  },
  columnValue: {
    title: 'Select a column to filter by and define the filter parameters.',
    data: 'Data',
    //     helper:
    //       'The target column data must be numbers (float) or text (string).',
    params: 'Parameters',
    column: 'Target column',
    selectValPlaceholder: 'Select values',
    textFilterLabel: 'Results',
    values: 'Values',
    keep: 'Keep',
    discard: 'Discard',
    numberNotAvailable: 'Coming soon',
    selectColumn: 'Please select a source column',
    numberFilter: {
      label: 'Filter operator',
      type: {
        between: 'Between',
        greaterThan: 'Is greater than...',
        lessThan: 'Is less than...',
        equal: 'Is equal to...',
        lessThanOrEqual: 'Is less than or equal to...',
        greaterThanOrEqual: 'Is greater than or equal to...'
      },
      input: {
        slide: 'Values',
        label: 'Value',
        placeholder: 'type a value',
        max: 'Max value:',
        min: 'Min value:'
      }
    }
  },
  centroid: {
    title:
      'Find the centroid of the geometries in your data. Optionally, find a centroid for each category and/or aggregate data from a column in your data.',
    data: 'Data',
    params: 'Parameters',
    categorize: 'Categorize',
    aggregationGroup: 'Aggregation',
    aggregationOp: 'Aggregation operation',
    aggregationField: 'Aggregation column',
    selectColumn: 'Select a column',
    selectOp: 'Select an operation',
    operatorNone: 'None'
  },
  clusteringKMeans: {
    title:
      'Analyze a set of points to classify them into a custom number of spatial clusters. A new column will be added containing the cluster number each point belongs to.',
    data: 'Data',
    params: 'Parameters',
    clusters: 'Clusters'
  },
  tradeAreas: {
    title: 'Find areas within a custom time range around your points.',
    data: 'Data',
    params: 'Parameters',
    mode: 'Mode',
    car: 'Car',
    walk: 'Walk',
    range: 'Range type',
    distance: 'Distance',
    time: 'Time',
    meter: 'm',
    second: 'sec'
  },
  errorMessages: {
    orderByNotAvailable:
      '`ORDER BY` clauses are not compatible with table optimization strategies like clustering. Please remove the `ORDER BY` from your query and try saving the result again.'
  }
}
