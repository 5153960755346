import { ReactNode } from 'react'
import {
  DialogActions as MuiDialogActions,
  DialogActionsProps as MuiDialogActionsProps,
  styled,
  Box,
  Divider
} from '@mui/material'
import { AlertProps } from '@carto/react-ui'
import { DialogAlert } from './DialogAlert'

export type DialogFooterProps = MuiDialogActionsProps & {
  secondaryActions?: ReactNode
  alertContent?: ReactNode
  alertProps?: AlertProps
  dark?: boolean
  topContent?: ReactNode
}

const Root = styled(Box, {
  shouldForwardProp: (prop: string) => !['dark', 'hasChildren'].includes(prop)
})<{
  dark?: boolean
  hasChildren?: boolean
}>(({ dark, hasChildren, theme }) => ({
  flex: '0 0 auto',
  display: 'block',
  minHeight: hasChildren ? theme.spacing(8) : 'auto',
  zIndex: 10,

  ...(dark && {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.white[60],

    '.MuiAlert-root': {
      '.MuiTypography-root, .MuiAlert-message :not(.MuiAlertTitle-root)': {
        color: theme.palette.white[60],

        a: {
          color: `${theme.palette.secondary.main} !important`
        }
      },
      '.MuiAlert-icon': {
        color: theme.palette.white[60]
      }
    }
  })
}))

const Main = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',

  '& > div:empty': {
    padding: 0
  }
}))

const ItemsWrapper = styled(Box, { shouldForwardProp: (prop) => prop !== 'hasAlert' })<{ hasAlert?: boolean }>(
  ({ hasAlert, theme }) => ({
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
    padding: theme.spacing(2),
    paddingTop: hasAlert ? 0 : theme.spacing(2)
  })
)

export const DialogActions = styled(MuiDialogActions, { shouldForwardProp: (prop) => prop !== 'hasAlert' })<{
  hasAlert?: boolean
}>(({ hasAlert, theme }) => ({
  gap: theme.spacing(1),
  padding: theme.spacing(2),
  paddingTop: hasAlert ? 0 : theme.spacing(2),
  margin: 0,

  '.MuiButton-root + .MuiButton-root': {
    marginLeft: 0
  }
}))

const DividerTop = styled(Divider)(() => ({
  // To prevent double border in the top when content is scrollable
  marginTop: '-1px'
}))

export function DialogFooter({
  children,
  secondaryActions,
  alertContent,
  alertProps,
  dark,
  topContent,
  ...otherProps
}: DialogFooterProps) {
  return (
    <>
      {topContent}
      {!!alertContent && <DividerTop />}
      <Root {...otherProps} dark={dark} hasChildren={!!children}>
        {!!alertContent && (
          <Box p={2} pt={1.5} pb={1}>
            <DialogAlert {...alertProps}>{alertContent}</DialogAlert>
          </Box>
        )}

        <Main>
          <ItemsWrapper hasAlert={!!alertContent}>{secondaryActions}</ItemsWrapper>

          <DialogActions hasAlert={!!alertContent}>{children}</DialogActions>
        </Main>
      </Root>
    </>
  )
}
