import { Box, Paper, styled } from '@mui/material'
import { ReactNode } from 'react'

import LoadingContent from 'features/common/ui/Loaders/LoadingContent'
import ModalWrapper from 'features/common/ui/utils/ModalWrapper'
import { DialogSizes, paperDialogBySize } from 'features/common/ui/Dialog/DialogPaper'
import { DialogHeader } from 'features/common/ui/Dialog'
import ErrorPage from 'features/errors/ui/ErrorPage'

import CartoLogo from 'assets/img/carto-logo.svg?react'

const Page = styled(Box)(() => ({
  width: '100%',
  height: '100vh'
}))

const LogoWrapper = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: theme.spacing(-2.5),
  left: '50%',
  transform: 'translate3d(-50%, -100%, 0)'
}))

const CartoLogoPositive = styled(CartoLogo)(({ theme }) => ({
  fill: theme.palette.black[90]
}))

const PromptPaper = styled(Paper)(({ theme }) => ({
  borderRadius: theme.spacing(1),
  ...paperDialogBySize[DialogSizes.Small]
}))

interface PromptProps {
  title?: ReactNode
  isLoading?: boolean
  children: ReactNode
  logoPositive?: boolean
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  error?: any
}

function Prompt({ title, isLoading, logoPositive, children, error }: PromptProps) {
  if (isLoading) {
    return <LoadingContent />
  }

  if (error) {
    return <ErrorPage code={error.status} />
  }

  const hideBackdrop = !!logoPositive

  return (
    <Page>
      <ModalWrapper open={true} displayCloseIcon={false} closeOnBackdrop={false} hideBackdrop={hideBackdrop}>
        <Box display="flex">
          <LogoWrapper>
            <a href="/">{logoPositive ? <CartoLogoPositive /> : <CartoLogo />}</a>
          </LogoWrapper>

          <PromptPaper>
            <Box flex={1}>
              {title && <DialogHeader title={title} />}
              {children}
            </Box>
          </PromptPaper>
        </Box>
      </ModalWrapper>
    </Page>
  )
}

export default Prompt
