import { ReactNode, useState, MouseEvent } from 'react'
import {
  IconButton as MuiIconButton,
  IconButtonProps as MuiIconButtonProps,
  Tooltip,
  TooltipProps
} from '@mui/material'
import { styled } from '@mui/material/styles'
import clsx from 'clsx'

const Option = styled('div')(({ theme }) => ({
  position: 'relative',
  display: 'inline-flex',

  '& + &, & + .simpleIconButton': {
    // TODO: this should be removed and buttons should be separated by proper GAP
    marginLeft: theme.spacing(0.5)
  }
}))

const StyledIconButton = styled(MuiIconButton, {
  shouldForwardProp: (prop: string) => !['active', 'error', 'warning', 'variant'].includes(prop)
})<{
  variant?: SimpleIconButtonProps['variant']
  active?: boolean
  error?: boolean
  warning?: boolean
  disabled?: boolean
}>(({ active, error, warning, theme, disabled, variant }) => {
  const contained = variant === 'contained'

  const backgroundColor = () => {
    if (disabled && contained) {
      return `${theme.palette.action.disabledBackground} !important`
    }

    if (contained) {
      return theme.palette.primary.main
    }

    return 'inherit'
  }

  return {
    position: 'relative',
    overflow: 'hidden',
    color: contained ? theme.palette.primary.contrastText : undefined,

    '& + &, & + .optionIconButton': {
      marginLeft: theme.spacing(0.5)
    },
    '& svg': {
      fill: 'currentColor',

      '& path': {
        fillOpacity: 1
      }
    },
    '&.Mui-disabled svg:not(.doNotFillIcon) path': {
      fill: theme.palette.text.disabled
    },

    backgroundColor: backgroundColor(),
    borderWidth: contained && disabled ? 0 : 1,

    '&:hover': {
      backgroundColor: contained ? theme.palette.primary.dark : undefined
    },

    // Accessibility focus (keyboard only)
    '&:focus-visible': {
      outline: `2px solid ${theme.palette.primary.main} !important`,
      boxShadow: `0 0 0 4px ${theme.palette.primary.background} !important`
    },

    ...(active && {
      color: contained ? theme.palette.primary.contrastText : theme.palette.primary.main,
      backgroundColor: contained ? theme.palette.primary.dark : theme.palette.primary.background,

      '& svg:not(.doNotFillIcon) path': {
        fill: contained ? theme.palette.primary.contrastText : theme.palette.primary.main
      }
    }),
    ...(error && {
      '& svg:not(.doNotFillIcon) path': {
        fill: theme.palette.error.main
      }
    }),
    ...(warning && {
      '& svg:not(.doNotFillIcon) path': {
        fill: theme.palette.warning.main
      }
    })
  }
})

interface SimpleIconButtonProps extends Omit<MuiIconButtonProps, 'icon'> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onClick?: (event?: any) => void
  active?: boolean
  error?: boolean
  warning?: boolean
  icon: ReactNode
  color?: 'default' | 'inherit' | 'primary' | 'secondary'
  variant?: 'contained' | 'outlined'
  'data-testid'?: string
}

function SimpleIconButton({
  active,
  error,
  warning,
  icon,
  size = 'medium',
  className,
  color = 'default',
  variant = 'outlined',
  ...props
}: SimpleIconButtonProps) {
  return (
    <StyledIconButton
      {...props}
      size={size}
      classes={{
        root: clsx('simpleIconButton', className)
      }}
      active={active}
      error={error}
      warning={warning}
      color={color}
      variant={variant}
    >
      {icon}
    </StyledIconButton>
  )
}

export type TooltipPlacementType = TooltipProps['placement']

export interface IconButtonProps extends SimpleIconButtonProps {
  tooltip?: string | boolean | ReactNode
  tooltipPlacement?: TooltipPlacementType
  keepTooltipShown?: boolean

  // Some buttons have tooltip disabled/enabled and we want to keep identity of DOM and focused
  // node even when tooltip is enabled/disabled.
  // Use this to always render stable DOM tree with tooltip.
  forceRenderWithTooltip?: boolean
}

function IconButton({
  tooltip,
  tooltipPlacement = 'bottom',
  keepTooltipShown,
  forceRenderWithTooltip,
  ...props
}: IconButtonProps) {
  const [showTooltip, setShowTooltip] = useState<boolean>(false)

  const controlledTooltipProps =
    tooltip && keepTooltipShown
      ? {
          open: showTooltip
        }
      : {}

  const controlledTooltipOptionProps =
    tooltip && keepTooltipShown
      ? {
          onMouseEnter: () => {
            if (keepTooltipShown) return
            setShowTooltip(true)
          },
          onMouseLeave: () => {
            if (keepTooltipShown) return
            setShowTooltip(false)
          }
        }
      : {}

  if (tooltip || forceRenderWithTooltip) {
    return (
      <Tooltip arrow placement={tooltipPlacement} title={tooltip} {...controlledTooltipProps}>
        <Option {...controlledTooltipOptionProps} className="optionIconButton">
          <SimpleIconButton
            {...props}
            onClick={(event: MouseEvent) => {
              !keepTooltipShown && setShowTooltip(false)
              if (props.onClick) {
                props.onClick(event)
              }
            }}
          />
        </Option>
      </Tooltip>
    )
  }

  return <SimpleIconButton {...props} />
}

export default IconButton
