import { createTheme } from '@mui/material'
import { theme as originalCartoTheme, cartoThemeOptions } from '@carto/react-ui'
import { Branding, getMUIThemePropsFromBranding } from 'features/branding/models/Branding'
import cartoLogoDot from 'assets/img/carto-logo-dot.svg'

export function getCustomTheme(branding?: Branding) {
  const custom = branding ? getMUIThemePropsFromBranding(branding) : {}
  const customOptions = {
    palette: {
      ...cartoThemeOptions.palette,
      ...custom?.palette
    },
    components: {
      ...cartoThemeOptions.components,
      MuiCssBaseline: {
        styleOverrides: {
          'body[data-dragging="true"]': {
            cursor: 'col-resize'
          }
        }
      }
    }
  }
  return createTheme(originalCartoTheme, customOptions)
}

const theme = getCustomTheme()

export function getDefaultBrandingFromTheme({ theme }) {
  const defaultBranding: Branding = {
    logoUrl: cartoLogoDot,
    primaryColor: theme.palette.primary.main,
    secondaryColor: theme.palette.secondary.main,
    usePrimaryColorsForAppBar: false,
    appBarBackgroundColor: theme.palette.brand.navyBlue,
    appBarContrastTextColor: theme.palette.primary.contrastText,
    disableCartoFooter: false,
    disableOnboarding: false
  }
  return defaultBranding
}

export const defaultBranding = getDefaultBrandingFromTheme({ theme })

export default theme
