import { useRef, useState, forwardRef, useEffect, useMemo, ReactNode, HTMLAttributes, MutableRefObject } from 'react'
import { Tooltip, TooltipProps, styled } from '@mui/material'

const TooltipRoot = styled(Tooltip)(() => ({
  whiteSpace: 'nowrap',
  maxWidth: 999,
  pointerEvents: 'auto'
}))

const Content = styled('span')(({ theme }) => ({
  display: 'block',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  fontWeight: 'inherit',
  paddingBottom: theme.spacing(0.25),

  '& > *': {
    display: 'inline'
  }
}))

export type EllipsisWithTooltipProps = Omit<TooltipProps, 'title' | 'children'> & {
  children: ReactNode
  title?: string
  forceTooltip?: boolean
  textRef?: MutableRefObject<HTMLElement | null>
  contentProps?: HTMLAttributes<HTMLSpanElement>
}

const EllipsisWithTooltip = forwardRef(
  (
    { children, title, forceTooltip, contentProps, textRef: inputTextRef, ...tooltipProps }: EllipsisWithTooltipProps,
    ref
  ) => {
    const textElementRef = useRef<HTMLElement | null>(null)
    const [overflowing, setOverflowing] = useState<boolean>(false)
    const textRef = useMemo(() => inputTextRef || textElementRef, [inputTextRef, textElementRef])

    const elementTitle = title || textElementRef.current?.innerText || ''

    useEffect(() => {
      if (children && textRef.current) {
        setOverflowing(forceTooltip ?? textRef.current.scrollWidth > textRef.current.clientWidth)
      }
    }, [textRef, forceTooltip, children])

    useEffect(() => {
      if (typeof ref === 'function') {
        ref(textElementRef.current)
      } else if (ref) {
        ref.current = textElementRef.current
      }
    }, [ref])

    return (
      <TooltipRoot
        disableFocusListener={!overflowing}
        disableHoverListener={!overflowing}
        disableTouchListener={!overflowing}
        title={elementTitle}
        describeChild
        {...tooltipProps}
      >
        <Content aria-label={elementTitle} {...contentProps} ref={textElementRef}>
          {children}
        </Content>
      </TooltipRoot>
    )
  }
)

export default EllipsisWithTooltip
