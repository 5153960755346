import { Box, Button, Link } from '@mui/material'
import { Typography } from '@carto/react-ui'
import makeStyles from '@mui/styles/makeStyles'
import CancelIcon from 'assets/icons/icon-navigation-cancel.svg?react'
import ActionExploreIcon from 'assets/icons/icon-action-explore-off.svg?react'
import { FormattedMessage } from 'react-intl'
import { isInIframe } from 'app/utils/embedding'
import { useSearchParams } from 'react-router-dom'
const useStyle = makeStyles((theme) => ({
  root: {
    maxWidth: '100%',
    margin: '0 auto',
    position: 'relative',
    paddingLeft: theme.spacing(2.5),
    paddingRight: theme.spacing(2.5),
    paddingTop: theme.spacing(18.5),
    [theme.breakpoints.up('xs')]: {},
    [theme.breakpoints.up('sm')]: {
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4)
    },
    [theme.breakpoints.up('md')]: {
      paddingLeft: theme.spacing(5),
      paddingRight: theme.spacing(5)
    },
    [theme.breakpoints.up('lg')]: {
      maxWidth: theme.spacing(150)
    },
    [theme.breakpoints.up('xl')]: {
      maxWidth: theme.spacing(170),
      paddingLeft: 0,
      paddingRight: 0
    },
    '&.other': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      paddingTop: theme.spacing(4)
    }
  },
  title: {
    fontSize: theme.spacing(6)
  },
  subtitle: {
    color: theme.palette.text.secondary
  },
  link: {
    cursor: 'pointer',
    fontSize: theme.spacing(2)
  }
}))

const ERRORS = {
  404: {
    icon: <ActionExploreIcon />,
    title: <FormattedMessage id="common.errors.404.title" />,
    subtitle: <FormattedMessage id="common.errors.404.subtitle" />
  },
  500: {
    icon: <CancelIcon />,
    title: <FormattedMessage id="common.errors.500.title" />,
    subtitle: <FormattedMessage id="common.errors.500.subtitle" />
  }
}

interface ErrorPageProps {
  code: '500' | '404'
}
function ErrorPage({ code }: ErrorPageProps) {
  const classes = useStyle()
  const error = ERRORS[code]
  const [searchParams] = useSearchParams()

  const redirectTo = searchParams.get('redirectTo') || '/'

  const contactLink = {
    link: (
      <Typography color="textPrimary" component={'span'}>
        <Link href={'mailto:support@carto.com'} className={classes.link}>
          <FormattedMessage id="common.errors.other.linkLabel" />
        </Link>
      </Typography>
    )
  }

  return (
    <div className={`${classes.root} ${!ERRORS[code] && 'other'}`}>
      {error.icon}
      <Box mt={1.5}>
        <Typography className={code ? classes.title : ''} variant={code ? 'h2' : 'subtitle1'}>
          <FormattedMessage id="common.errors.title" values={{ code: code ? code : 500 }} />. {!code && error.title}
        </Typography>
      </Box>
      {code && <Typography variant="h5">{error.title}</Typography>}
      <Box mt={2} mb={4} textAlign={!code ? 'center' : 'unset'}>
        <Typography className={classes.subtitle} variant="body1">
          {error.subtitle}
        </Typography>
        {!code && (
          <Typography className={classes.subtitle} variant="body1">
            <FormattedMessage id="common.errors.other.contact" values={contactLink} />
          </Typography>
        )}
      </Box>
      {code && (
        <Link href={redirectTo} underline="none" target={isInIframe ? '_blank' : undefined}>
          <Button size="large" variant="contained" color="primary">
            <FormattedMessage id="common.errors.back" />
          </Button>
        </Link>
      )}
    </div>
  )
}

export default ErrorPage
