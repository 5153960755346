import { useCallback, useEffect, useState } from 'react'
import {
  DialogContent as MuiDialogContent,
  DialogContentProps as MuiDialogContentProps,
  styled,
  useTheme
} from '@mui/material'

const Content = styled(MuiDialogContent, {
  shouldForwardProp: (prop: string) => !['scrollableContent', 'withGutter', 'withBottomGutter'].includes(prop)
})<{
  scrollableContent?: boolean
  withGutter?: boolean
  withBottomGutter?: boolean
}>(({ theme, scrollableContent, withGutter, withBottomGutter }) => ({
  // note on important usage:, somehow we have  MuiDialogContent-root / paddingTop: 0 rule, that has to be overriden
  padding: '0 !important',
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(3),
  ...theme.typography.body2,
  color: theme.palette.text.secondary,

  '.MuiAlert-root': {
    minHeight: 'auto'
  },

  ...(scrollableContent && {
    borderBottom: `1px solid ${theme.palette.divider}`,

    '+ .MuiDivider-root': {
      display: 'none'
    }
  }),
  ...(withGutter && {
    padding: `${theme.spacing(1, 2, 3)} !important`,

    '.MuiDialog-paperFullScreen &': {
      padding: `${theme.spacing(2, 2, 3)} !important`
    }
  }),
  ...(!withBottomGutter && {
    paddingBottom: '0 !important',

    '.MuiDialog-paperFullScreen &': {
      paddingBottom: '0 !important'
    }
  })
}))

export const DialogContentPadded = styled(DialogContent, { shouldForwardProp: (prop) => prop !== 'withAlert' })<{
  withAlert?: boolean
}>(({ withAlert, theme }) => ({
  padding: `${theme.spacing(0, 2)} !important`,

  ...(withAlert && {
    paddingBottom: `${theme.spacing(3)} !important`
  })
}))

export interface DialogContentProps extends MuiDialogContentProps {
  scrollableContent?: boolean
  withGutter?: boolean
  withBottomGutter?: boolean
  /**
   * Set to true of scrollableContent and <DialogFooter> has alert and we should always show
   * bottom divider.
   *
   * Note, in future it may be not necessary if we choose to introduce Context based communication
   * between Dialog components.
   */
  hasFooterAlert?: boolean
}

export function DialogContent({
  children,
  scrollableContent,
  hasFooterAlert,
  withGutter = true,
  withBottomGutter = true,
  ...props
}: DialogContentProps) {
  const theme = useTheme()

  const [contentRef, setContentRef] = useState<HTMLElement | null>(null)
  const handleScroll = useCallback(
    (event) => {
      const node = event.target
      const bottom = node.scrollHeight - node.scrollTop === node.clientHeight
      const top = node.scrollTop === 0

      const borderStyle = `1px solid ${theme.palette.divider}`

      node.style.borderTop = top ? 'none' : borderStyle

      node.style.borderBottom = bottom && !hasFooterAlert ? 'none' : borderStyle
    },
    [theme.palette.divider, hasFooterAlert]
  )

  useEffect(() => {
    if (!contentRef) return

    handleScroll({ target: contentRef })
  }, [contentRef, handleScroll])

  return (
    <Content
      ref={setContentRef}
      onScroll={handleScroll}
      scrollableContent={scrollableContent}
      withGutter={withGutter}
      withBottomGutter={withBottomGutter}
      {...props}
    >
      {children}
    </Content>
  )
}
