import yaml from 'js-yaml'
import { YamlConfig } from './YamlConfig'
interface Settings {
  domain: string
  audience: string
  organizationId: string
  clientId: string
  accountsApiUrl: string
  workspaceApiUrl: string
  workflowsApiUrl: string
  notifierUrl: string
  apiBaseUrl: string
  accountsUrl: string
  mapsApiUrl: string
  mapsPublicUrl: string
  ldsApiUrl: string
  recurlyUrl: string
  publicUrl: string
  hubspotId: string
  statsApiUrl: string
  hubspotLimitFormId: string
  hubspotSubscriptionFormId: string
  allScopes: string
  doCatalogApiUrl: string
  spatialDataCatalogUrl: string
  spatialDataCatalogAssetstUrl: string
  googleMapsApiKey: string
  importApiUrl: string
  publicCatalogUrl: string
  bigqueryOAuthEnabled: boolean
  enableFeaturedApplications: boolean
  enableTrackJs?: boolean
  localStorageOrgIdKey: string
  studentClientId: string
  loginAsStudentKey: string
  launchDarklyClientSideId: string
  cartoVersion: string
  isSnowflakeNativeApp: boolean
  documentationUrl: string
  pricingUrl: string
  feedbackUrl: string
  supportEmail: string
  exportBucket: string
  exportUrl: string
  enableExports: boolean
  enableGenAi: boolean
  cartoDwEnabled: boolean
  vitallyToken?: string
  commitHash: string
  cartoAcademyUrl: string
  connectionLimits: {
    maxConcurrentQueries: {
      min: number
      max: number
      default: number
    }
    queryTimeoutS: {
      min: number
      max: number
      default: number
    }
  }
  featureFlags: { name: string; value: boolean | string }[]
  authorizationHeader: string
}

const settings: Settings = {
  domain: '',
  audience: '',
  organizationId: '',
  clientId: '',
  accountsApiUrl: '',
  workspaceApiUrl: '',
  workflowsApiUrl: '',
  notifierUrl: '',
  apiBaseUrl: '',
  accountsUrl: '',
  statsApiUrl: '',
  publicUrl: import.meta.env.VITE_PUBLIC_URL || '',
  publicCatalogUrl: '',
  mapsApiUrl: '',
  mapsPublicUrl: '',
  ldsApiUrl: '',
  recurlyUrl: '',
  hubspotId: '',
  hubspotLimitFormId: '',
  hubspotSubscriptionFormId: '',
  doCatalogApiUrl: '',
  spatialDataCatalogUrl: '',
  spatialDataCatalogAssetstUrl: '',
  googleMapsApiKey: '',
  allScopes:
    'openid profile email read:current_user update:current_user read:connections write:connections read:maps write:maps read:account admin:account',
  importApiUrl: '',
  bigqueryOAuthEnabled: false,
  enableFeaturedApplications: false,
  localStorageOrgIdKey: 'orgId',
  studentClientId: '',
  loginAsStudentKey: 'student_account',
  launchDarklyClientSideId: '',
  cartoVersion: '',
  isSnowflakeNativeApp: false,
  documentationUrl: 'https://docs.carto.com',
  pricingUrl: 'https://carto.com/pricing/',
  feedbackUrl: 'https://cartohq.typeform.com/to/a4YHHwPS',
  supportEmail: 'support@carto.com',
  exportBucket: 'gs://carto-tnt-gcp-us-east1-export-storage',
  exportUrl: 'https://storage.googleapis.com/carto-tnt-gcp-us-east1-export-storage',
  enableExports: true,
  enableGenAi: true,
  cartoDwEnabled: true,
  vitallyToken: '',
  commitHash: '',
  cartoAcademyUrl: 'https://academy.carto.com',
  // Note that this is replicated from workspace-api settings;
  // ideally we should get these values from the backend.
  connectionLimits: {
    maxConcurrentQueries: {
      max: 1000,
      min: 1,
      default: 50
    },
    queryTimeoutS: {
      max: 50,
      min: 1,
      default: 20
    }
  },
  featureFlags: [],
  authorizationHeader: ''
}

export const DEFAULT_ANALYTICS_TOOLBOX_PROJECT = 'carto-un'
export const DEFAULT_AUTHORIZATION_HEADER = 'Authorization'

export async function loadConfig() {
  const configUrl = import.meta.env.VITE_APP_CONFIG_URL || '/config.yaml'

  const config = await fetchConfigYaml(configUrl)

  applyConfig(config)
}

export async function applyConfig(config: YamlConfig) {
  const { auth0, apis, hubspot, launchDarkly, cartoVersion } = config

  // AUTH0
  settings.domain = auth0.domain
  settings.audience = auth0.audience
  settings.clientId = auth0.clientId
  settings.studentClientId = auth0.studentClientId
  settings.organizationId = auth0.organizationId

  // APIs
  settings.apiBaseUrl = apis.baseUrl
  settings.accountsApiUrl = apis.accountsUrl
  settings.workspaceApiUrl = apis.workspaceUrl
  // TODO: remove fallback env vars. App should not start without nessecary env vars.
  // https://app.shortcut.com/cartoteam/story/455202
  settings.workflowsApiUrl = apis.workflowsUrl ? `${apis.workflowsUrl}/workflows` : `${apis.baseUrl}/v3/workflows`
  settings.notifierUrl = apis.notifierUrl
  settings.mapsApiUrl = `${apis.baseUrl}/v3/maps`
  settings.statsApiUrl = `${apis.baseUrl}/v3/stats`

  // TODO: remove fallback env vars. App should not start without nessecary env vars.
  // https://app.shortcut.com/cartoteam/story/455202
  settings.ldsApiUrl = apis.ldsApiUrl || apis.baseUrl
  settings.doCatalogApiUrl = apis.doUrl
  settings.spatialDataCatalogUrl = apis.spatialDataCatalogUrl
  settings.spatialDataCatalogAssetstUrl = apis.spatialDataCatalogAssetstUrl
  settings.importApiUrl = `${apis.importUrl || apis.baseUrl}/v3/imports`
  settings.recurlyUrl = `${apis.recurlyUrl}`

  // Hubspot
  settings.hubspotId = hubspot.id
  settings.hubspotLimitFormId = hubspot.limitFormId
  settings.hubspotSubscriptionFormId = hubspot.requestSubscriptionFormId

  // LaunchDarkly
  settings.launchDarklyClientSideId = launchDarkly.clientSideId

  // CARTO Version
  const selfHosted = cartoVersion.selfHosted
  settings.cartoVersion = selfHosted ? `${selfHosted}` : 'SaaS'
  settings.isSnowflakeNativeApp = cartoVersion.snowflakeNativeApp || false

  // TrackJS
  settings.enableTrackJs = import.meta.env.PROD && config.enableTrackJs

  // Export features
  const enableExportsNotConfigured =
    config.enableExports === '' || config.enableExports === undefined || config.enableExports === null
  settings.enableExports = enableExportsNotConfigured ? true : Boolean(config.enableExports)

  // GenAi features
  const enableGenAiNotConfigured =
    config.enableGenAi === '' || config.enableGenAi === undefined || config.enableGenAi === null
  settings.enableGenAi = enableGenAiNotConfigured ? true : Boolean(config.enableGenAi)

  // CartoDW enabled
  settings.cartoDwEnabled = Boolean(config.cartoDwEnabled)

  // common
  const accountsUrl = config.accountsUrl.endsWith('/')
    ? config.accountsUrl.substring(0, config.accountsUrl.length - 1)
    : config.accountsUrl // ensure we don't have a trailing slash
  settings.accountsUrl = accountsUrl
  settings.mapsPublicUrl = config.publicMapUrl
  settings.googleMapsApiKey = import.meta.env.VITE_GOOGLE_MAPS_API_KEY || config.googleMapsApiKey
  settings.publicCatalogUrl = config.publicCatalogUrl

  // TODO: this flag should be managed in Backend (maybe in /me),
  // they already have a flag that we could use to decide if we show or hide OAuth option
  settings.bigqueryOAuthEnabled = config.bigqueryOAuthEnabled
  settings.enableFeaturedApplications = config.enableFeaturedApplications

  // Vitally integration for NPS
  settings.vitallyToken = config.vitallyToken

  // GitHub
  settings.commitHash = config.commitHash

  // Custom self-hosted feature flags
  if (config.featureFlagsOverrides) {
    settings.featureFlags = config.featureFlagsOverrides
  }

  settings.authorizationHeader = config.authorizationHeader || DEFAULT_AUTHORIZATION_HEADER
}

export function parseYamlConfig(data: string): YamlConfig {
  try {
    return yaml.load(data) as YamlConfig
  } catch (e) {
    const msg = e instanceof Error ? e.message : ''
    throw new Error(`Error parsing config file. ${msg}`)
  }
}

export async function fetchConfigYaml(configUrl: string) {
  let yamlText
  try {
    const response = await fetch(configUrl)

    if (!response.ok) {
      throw new Error(`File status code ${response.status}.`)
    }
    yamlText = await response.text()
    if (!yamlText) {
      throw new Error(`Empty config file detected.`)
    }
  } catch (e) {
    const msg = e instanceof Error ? e.message : ''
    throw new Error(`Cannot download file ${configUrl}. ${msg}`)
  }

  return parseYamlConfig(yamlText)
}

export default settings
